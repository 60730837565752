import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface FilesType {
    id: number;
    uploadDate: string;
    fileName: string;
    filePath: string;
}

export default function useFiles() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const { handleLogout, objUserTk } = useContext(AuthContext);
    const [fileName, setFileName] = useState<string>("");
    const [listFiles, setListFiles] = useState<FilesType[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const [initialListFiles, setInitialListFiles] = useState<FilesType[]>([]);
    const [Files, setFiles] = useState<FilesType>({
        id: 0,
        uploadDate: "",
        fileName: "",
        filePath: "",
    });
    
    function getAllFilesPaged(
        taxTasksId?: number
    ): Promise<FilesType[] | null> {
        setLoading(true);
        let url = `/File/getAllFilePaged?pageNumber=1&pageSize=50`;

        if (taxTasksId) {
            url += `&taxTasksId=${taxTasksId}`;
        }

        return api
            .get(url)
            .then((response) => {
                setListFiles(response.data.result);
                setInitialListFiles(response.data.result);
                setPageCount(response.data.pageCount);
                setLoading(false);
                return response.data.result;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao carregar arquivos. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao carregar arquivos:", error);
                return null;
            });
    }

    async function getFileName(taxTasksId: number): Promise<string | void> {
        setLoading(true);
        try {
            const response = await api.get(`/File/getFileNameByTaxTaskId?taxTaskId=${taxTasksId}`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setFileName(response.data);
            setLoading(false);
            return response.data;
        } catch (error: any) {
            if (error?.status === 401) {
                handleLogout();
                showToast("error", "Sessão desconectada. Favor login novamente.", error);
            } else {
                showToast("error", "Erro ao carregar nome do arquivo. Verifique novamente mais tarde.", error);
            }
            console.error("Erro ao nome do arquivo:", error);
        } finally {
            setLoading(false);
        }
    }

    return {
        getAllFilesPaged,
        getFileName,
        fileName,
        listFiles,
        initialListFiles,
        pageCount,
        Files,
        setFiles,
    };
}