import React, { useState, useEffect, useContext } from 'react';
import {
  Container, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, Row, Col, Card, CardBody, CardFooter, CardImg
} from 'reactstrap';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaCamera } from 'react-icons/fa'; // Importando o ícone de câmera

const TableOffice = () => {
  const { objUserTk } = useContext(AuthContext); // Pega o token do contexto
  const [offices, setOffices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newOffice, setNewOffice] = useState({ name: '', email: '', cnpj: '', area: '', password: '', photo: '' });
  const [selectedFile, setSelectedFile] = useState(null); // Armazena o arquivo de imagem selecionado
  const [previewPhoto, setPreviewPhoto] = useState(null); // Previsão da foto
  const [modalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editOfficeId, setEditOfficeId] = useState(null);

  useEffect(() => {
    fetchOffices();
  }, []);

  // Função para buscar todos os escritórios
  const fetchOffices = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://localhost:44319/api/Office/getAllOfficePaged', {
        headers: { Authorization: `Bearer ${objUserTk.token}` },
      });
      setOffices(response.data.result);
    } catch (error) {
      console.error('Erro ao buscar escritórios:', error);
      toast.error("Erro ao buscar escritórios.");
    } finally {
      setLoading(false);
    }
  };

  // Função para criar ou editar um escritório
  const handleSaveOffice = async () => {
    const payload = {
      name: newOffice.name,
      email: newOffice.email,
      password: newOffice.password,
      cnpj: newOffice.cnpj,
      area: newOffice.area,
      photo: previewPhoto || '', // Adiciona a foto ou mantém em branco
      user: {
        id: 0,
        name: newOffice.name,
        email: newOffice.email,
        password: newOffice.password,
        type: 1,
      },
    };

    try {
      if (editMode && editOfficeId !== null) {
        await axios.put(`https://localhost:44319/api/Office/${editOfficeId}`, payload, {
          headers: {
            Authorization: `Bearer ${objUserTk.token}`,
            'Content-Type': 'application/json',
          },
        });
        toast.success("Escritório atualizado com sucesso!");
      } else {
        await axios.post('https://localhost:44319/api/Office/create', payload, {
          headers: {
            Authorization: `Bearer ${objUserTk.token}`,
            'Content-Type': 'application/json',
          },
        });
        toast.success("Escritório criado com sucesso!");
      }
      fetchOffices();
      resetForm();
    } catch (error) {
      console.error('Erro ao salvar escritório:', error);
      toast.error("Erro ao salvar escritório.");
    }
  };

  // Função para deletar um escritório
  const deleteOffice = async (officeId) => {
    try {
      await axios.delete(`https://localhost:44319/api/Office/${officeId}`, {
        headers: { Authorization: `Bearer ${objUserTk.token}` },
      });
      toast.success("Escritório deletado com sucesso!");
      fetchOffices();
    } catch (error) {
      console.error('Erro ao deletar escritório:', error);
      toast.error("Erro ao deletar escritório.");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setPreviewPhoto(URL.createObjectURL(file));
  };

  const resetForm = () => {
    setNewOffice({ name: '', email: '', cnpj: '', area: '', password: '', photo: '' });
    setSelectedFile(null);
    setPreviewPhoto(null);
    setModalOpen(false);
    setEditMode(false);
    setEditOfficeId(null);
  };

  const validateForm = () => {
    return newOffice.name && newOffice.email && newOffice.cnpj && newOffice.password;
  };

  return (
    <Container className="py-4">
      <h3 className="mb-4 text-center">Tabela de Escritórios</h3>
      <Button color="primary" onClick={() => setModalOpen(true)} className="mb-4">
        <i className="fa fa-plus mr-2"></i>Adicionar Escritório
      </Button>

      {loading ? (
        <div>Carregando...</div>
      ) : (
        <Table hover responsive className="table-striped">
          <thead className="thead-dark">
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>CNPJ</th>
              <th>Área</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {offices.map((office) => (
              <tr key={office.id}>
                <td>{office.name}</td>
                <td>{office.email}</td>
                <td>{office.cnpj}</td>
                <td>{office.area}</td>
                <td>
                  <Button color="warning" className="mr-2" onClick={() => {
                    setNewOffice({ ...office, password: '' });
                    setPreviewPhoto(office.photo);
                    setEditMode(true);
                    setEditOfficeId(office.id);
                    setModalOpen(true);
                  }}>
                    <i className="fa fa-edit"></i>
                  </Button>
                  <Button color="danger" onClick={() => deleteOffice(office.id)}>
                    <i className="fa fa-trash"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Modal para criar/editar escritório */}
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} className="modal-lg">
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          {editMode ? "Editar Escritório" : "Criar Novo Escritório"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col className="text-center">
                {/* Imagem circular para a pré-visualização */}
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  <div style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '50%',
                    backgroundColor: '#f4f4f4',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '10px',
                    position: 'relative',
                  }}>
                    {previewPhoto ? (
                      <img
                        src={previewPhoto}
                        alt="Pré-visualização"
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          objectFit: 'cover',
                        }}
                      />
                    ) : (
                      <i className="fa fa-user fa-5x text-muted"></i>
                    )}
                  </div>
                  {/* Ícone de câmera */}
                  <label htmlFor="photoUpload" style={{
                    position: 'absolute',
                    bottom: '-10px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: '#5e72e4',
                    borderRadius: '50%',
                    padding: '8px',
                    cursor: 'pointer',
                  }}>
                    <FaCamera style={{ color: '#fff', fontSize: '18px' }} />
                  </label>
                  <input
                    id="photoUpload"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                </div>
              </Col>
            </Row>

            {/* Demais campos do formulário */}
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="name">Nome</Label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Digite o nome do escritório"
                    value={newOffice.name}
                    onChange={(e) => setNewOffice({ ...newOffice, name: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    placeholder="Digite o email"
                    value={newOffice.email}
                    onChange={(e) => setNewOffice({ ...newOffice, email: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="password">Senha</Label>
                  <Input
                    type="password"
                    name="password"
                    placeholder="Digite a senha"
                    value={newOffice.password}
                    onChange={(e) => setNewOffice({ ...newOffice, password: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="cnpj">CNPJ</Label>
                  <Input
                    type="text"
                    name="cnpj"
                    placeholder="Digite o CNPJ"
                    value={newOffice.cnpj}
                    onChange={(e) => setNewOffice({ ...newOffice, cnpj: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="area">Área</Label>
                  <Input
                    type="text"
                    name="area"
                    placeholder="Digite a área de atuação"
                    value={newOffice.area}
                    onChange={(e) => setNewOffice({ ...newOffice, area: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveOffice} disabled={!validateForm()}>
            {editMode ? "Salvar Alterações" : "Criar"}
          </Button>
          <Button color="secondary" onClick={resetForm}>Cancelar</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default TableOffice;
