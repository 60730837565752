import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";
import { set } from "lodash";

export interface RoutineTypeType {
    id: number;
    type: string;    
    office: {
        id: number;
        name: string;
    };
}

export interface CreateRoutineTypeType {
    type: string;
    officeId: number;
}

export interface UpdateRoutineTypeType {
    id: number;
    type: string;
}

export default function useRoutineType() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const { handleLogout, objUserTk } = useContext(AuthContext);
    const [listRoutineType, setListRoutineType] = useState<RoutineTypeType[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const [initialListRoutineType, setInitialListRoutineType] = useState<RoutineTypeType[]>([]);
    const [routineType, setRoutineType] = useState<RoutineTypeType>({
        id: 0,
        type: "",
        office: {
            id: 0,
            name: "",
        },
    });

    function createRoutineType(createRoutineTypeType: CreateRoutineTypeType): Promise<CreateRoutineTypeType | null> {
        setLoading(true);
        return api
            .post(`/RoutineType/create`, createRoutineTypeType)
            .then((response) => {
                const createdRoutineType: CreateRoutineTypeType = response.data;
                showToast("success", "Tipo de rotina criada com sucesso.");
                setLoading(false);
                return createdRoutineType;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao criar tipo de rotia. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao criar tipo de rotina:", error);
                return null;
            });
    }

    function getAllRoutineTypePaged(
        pageNumber: number,
        officeId?: number,
    ): Promise<RoutineTypeType[] | null> {
        setLoading(true);
        let url = `/RoutineType/getAllRoutineTypePaged?pageNumber=${pageNumber}&pageSize=9`;

        if (officeId) {
            url += `&officeId=${officeId}&routineId=0`;
        }

        return api
            .get(url)
            .then((response) => {
                setListRoutineType(response.data.result);
                setInitialListRoutineType(response.data.result);
                setPageCount(response.data.pageCount);
                setLoading(false);
                return response.data.result;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao carregar tipo de rotinas. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao carregar tipo de rotinas:", error);
                return null;
            });
    }

    function updateRoutineType(updateRoutineTypeData: UpdateRoutineTypeType): Promise<UpdateRoutineTypeType | null> {
        setLoading(true);
        return api
            .put(`/RoutineType/${updateRoutineTypeData.id}`, updateRoutineTypeData)
            .then((response) => {
                showToast("success", "Tipo de rotina atualizada com sucesso.");
                setLoading(false);
                return response.data;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao atualizar tipo de rotina. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao atualizar tipo de rotina:", error);
                return null;
            });
    }

    function deleteRoutineType(routineTypeId: number): Promise<RoutineTypeType | null> {
        setLoading(true);
        return api
            .delete(`/RoutineType/${routineTypeId}`)
            .then(() => {
                setRoutineType({
                    id: 0,
                    type: "",
                    office: {
                        id: 0,
                        name: "",
                    },
                });
                showToast("success", "Tipo de rotina deletada com sucesso.");
                setLoading(false);
                return null;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao deletar tipo de rotina. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao deletar tipo de rotina:", error);
                return null;
            });
    }

    return {
        createRoutineType,
        getAllRoutineTypePaged,
        updateRoutineType,
        deleteRoutineType,
        setListRoutineType,
        listRoutineType,
        setPageCount,
        pageCount,
        setInitialListRoutineType,
        initialListRoutineType,
        setRoutineType,
        routineType,
    };
}