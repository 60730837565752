import React, { useContext } from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import { Container, Row } from "reactstrap";

import "./Auth.css";
import {routes} from "../../routes.js";
import { AuthContext } from "../../context/AuthContext";

const Auth = (props: any) => {

  const { selectTypeUser } = useContext(AuthContext)

  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
    if (mainContent.current) {
      //@ts-ignore
      mainContent.current.scrollTop = 0;
    }
  }, [location]);

  const getRoutes = (routes: any) => {
    return routes.map((prop: any, key: number) => {
      if (prop.layout === "/auth") {
        return (
          //@ts-ignore
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div
      style={{height: "calc(100vh)"}}
        className="bg-gradient-info-custom-bottom"
        ref={mainContent}
      >
        <div className="bg-gradient-info-custom py-7 py-lg-8">
          <div className="ml-7 position-absolute hide-on-small-screen">
            <div  className="mb--4 pl-5 text-login-1">
              Seja
            </div>
            <div className="pl-8 text-login-1">
              Bem-Vindo(a)
            </div>
          </div>
        </div>
        <div className="ml-7 position-absolute text-center hide-on-small-screen">
          <div className="mb--4 pl-5 font-weight-bold text-student">
            {selectTypeUser === 1 ? "ESCRITÓRIO" : "CLIENTE"}
          </div>
          <div className="mb--4 pl-5 ml-7 mt-3 start-5 end-0 text-center font-weight-light description-login">
          {selectTypeUser === 1 ? "Logue em sua conta para acessar o portal do contador" : "Logue em sua conta para acessar o portal do cliente"} 
          </div>
        </div>
        <Container className="position-card-login">
          <Row className="justify-content-end">
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/auth/login" replace />} />
            </Routes>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Auth;
