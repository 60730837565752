import { ReactNode, createContext } from "react";
import useRoutine, { RoutineType, CreateRoutineType, UpdateRoutineType } from "./hooks/useRoutine";

interface RoutineContextProviderProps {
    children: ReactNode;
}

export interface RoutineContextData {
    getAllRoutinePaged: (
        pageNumber: number,
        officeId?: number,
        customerId?: number,
        userCustomerId?: number,
        description?: string,
        initialDate?: string,
        finalDate?: string,
        frequency?: string,
    ) => Promise<RoutineType[] | null>;
    setRoutine: any;
    routine: RoutineType;
    listRoutine: RoutineType[];
    initialListRoutine: RoutineType[];
    setListRoutine: any;
    setPageCount: any;
    pageCount: number;
    createRoutine: (createRoutineType: CreateRoutineType) => Promise<CreateRoutineType | null>;
    updateRoutine: (updateRoutineType: UpdateRoutineType) => Promise<UpdateRoutineType | null>;
    deleteRoutine: (id: number) => Promise<RoutineType | null>;
}

const initialState: RoutineContextData = {
    getAllRoutinePaged: async (
        pageNumber: number,
        officeId?: number,
        customerId?: number,
        userCustomerId?: number,
        description?: string,
        initialDate?: string,
        finalDate?: string,
        frequency?: string,
    ) => {
        return Promise.resolve(null);
    },
    setRoutine: () => {},
    routine: {
        id: 0,
        description: "",
        initialDate: "",
        finalDate: "",
        numberOfCharges: 0,
        frequency: "",
        billingFrequency: 0,
        // customer: {
        //     razaoSocial: "",
        // },
        // routineType: {
        //     id: 0,
        //     type: "",
        // },
        razaoSocial: "",
        type: "",
        routineTypeId: 0,
        emails: [],
        tags: []
    },
    listRoutine: [],
    initialListRoutine: [],
    setListRoutine: () => {},
    setPageCount: () => {},
    pageCount: 0,
    createRoutine: (createRoutineType: CreateRoutineType) => {
        return Promise.resolve(null);
    },
    updateRoutine: (updateRoutineType: UpdateRoutineType) => {
        return Promise.resolve(null);
    },
    deleteRoutine: (id: number) => {
        return Promise.resolve(null);
    },
};

export const RoutineContext = createContext<RoutineContextData>(initialState);

export default function RoutineContextProvider({
    children,
}: RoutineContextProviderProps) {
    const {
        getAllRoutinePaged,
        setRoutine,
        routine,
        listRoutine,
        initialListRoutine,
        setListRoutine,
        setPageCount,
        pageCount,
        createRoutine,
        updateRoutine,
        deleteRoutine,
    } = useRoutine();

    return (
        <RoutineContext.Provider
            value={{
                getAllRoutinePaged,
                setRoutine,
                routine,
                listRoutine,
                initialListRoutine,
                setListRoutine,
                setPageCount,
                pageCount,
                createRoutine,
                updateRoutine,
                deleteRoutine,
            }}
        >
            {children}
        </RoutineContext.Provider>
    );
}
