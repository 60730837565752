import {
    Button,
    Card,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Spinner,
  } from "reactstrap";
  
  import { ChangeEvent, useState, useContext } from "react";
  import { AuthContext } from "../context/AuthContext";
  import moment from "moment";
  
  const Register = () => {
    const { officeRegister } = useContext(AuthContext);
  
    const [office, setOffice] = useState({
      name: "",
      email: "",
      password: "",
      cnpj: "",
      area: "",
      photo: "",
    });
  
    const [loading, setLoading] = useState(false);
  
    const handleRegisterClick = async () => {
      setLoading(true);
      const officeCreateRequest = {
        ...office,
        user: {
          id: 0,
          createdDate: moment().toISOString(),
          updatedDate: moment().toISOString(),
          name: office.name,
          email: office.email,
          password: office.password,
          type: 1,
        },
      };
      await officeRegister(officeCreateRequest);
      setLoading(false);
    };
  
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setOffice((prevOffice) => ({
        ...prevOffice,
        [name]: value,
      }));
    };
  
    return (
      <>
        {/* <Col lg="5" md="7" className="mx-auto mt-5"> */}
        <Col lg="5" md="7">
          <Card className="shadow-lg border-0 p-4" style={{ borderRadius: "20px", backgroundColor: "#f0f2f5" }}>
            {/* Logo do Escritório */}
            <img
              style={{ width: "63%" }}
              alt="Logo"
              className="rounded-circle mx-auto d-block mt-3 mb-4"
              src={require("../assets/img/brand/logoAtt.png")}
            />
            <div className="px-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Cadastro do Escritório</small>
              </div>
  
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-building" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="name"
                      placeholder="Nome do Escritório"
                      type="text"
                      value={office.name}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#fff", borderRadius: "10px" }}
                    />
                  </InputGroup>
                </FormGroup>
  
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="email"
                      placeholder="Email"
                      type="email"
                      value={office.email}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#fff", borderRadius: "10px" }}
                    />
                  </InputGroup>
                </FormGroup>
  
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="password"
                      placeholder="Senha"
                      type="password"
                      value={office.password}
                      onChange={handleInputChange}
                      style={{ backgroundColor: "#fff", borderRadius: "10px" }}
                    />
                  </InputGroup>
                </FormGroup>
  
                <div className="text-center">
                  <Button
                    onClick={handleRegisterClick}
                    className="my-4"
                    color="secondary"
                    type="button"
                    disabled={loading}
                    style={{ backgroundColor: "#6c757d", color: "#FFF", borderRadius: "12px" }}
                  >
                    {loading && <Spinner size="sm">Carregando...</Spinner>}
                    {" "}Cadastrar
                  </Button>
                </div>
              </Form>
            </div>
          </Card>
  
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-muted"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/auth/login";
                }}
              >
                <small>Já tem uma conta? Faça login</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  };
  
  export default Register;
  