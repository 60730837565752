import { useContext } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export default function useDownload() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const { handleLogout, objUserTk } = useContext(AuthContext);

    async function handleDownloadAll(officeId: number, routineId: number): Promise<void> {
        setLoading(true);
        try {
            const response = await api.get(`/Download/AllFiles/${officeId}/${routineId}`, {
                responseType: "blob",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `all_files_${routineId}.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            showToast("success", "Arquivos baixados com sucesso.");
        } catch (error: any) {
            if (error?.status === 401) {
                handleLogout();
                showToast("error", "Sessão desconectada. Favor login novamente.", error);
            } else {
                showToast("error", "Erro ao baixar arquivos. Verifique novamente mais tarde.", error);
            }
            console.error("Erro ao baixar arquivos:", error);
        } finally {
            setLoading(false);
        }
    }

    async function handleDownloadByFilename(filename: string, routineId: number): Promise<void> {
        setLoading(true);
        try {
            const response = await api.get(`/Download/FileByName/?fileName=${filename}&routineId=${routineId}`, {
                responseType: "blob",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            showToast("success", "Arquivo baixado com sucesso.");
        } catch (error: any) {
            if (error?.status === 401) {
                handleLogout();
                showToast("error", "Sessão desconectada. Favor login novamente.", error);
            } else {
                showToast("error", "Erro ao baixar arquivo. Verifique novamente mais tarde.", error);
            }
            console.error("Erro ao baixar arquivo:", error);
        } finally {
            setLoading(false);
        }
    }

    async function handleDownloadByTaxTasksId(officeId: number, taxTasksId: number): Promise<void> {
        setLoading(true);
        try {
            const response = await api.get(`/Download/FileByTaskId/${officeId}/${taxTasksId}`, {
                responseType: "blob",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `file_${taxTasksId}.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            showToast("success", "Arquivo baixado com sucesso.");
        } catch (error: any) {
            if (error?.status === 401) {
                handleLogout();
                showToast("error", "Sessão desconectada. Favor login novamente.", error);
            } else {
                showToast("error", "Erro ao baixar arquivo. Verifique novamente mais tarde.", error);
            }
            console.error("Erro ao baixar arquivo:", error);
        } finally {
            setLoading(false);
        }
    }

    return {
        handleDownloadAll,
        handleDownloadByFilename,
        handleDownloadByTaxTasksId,
    };
}