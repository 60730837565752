import { ReactNode, createContext } from "react";
import useRoutineType, { RoutineTypeType, CreateRoutineTypeType, UpdateRoutineTypeType} from "./hooks/useRoutinetype";

interface RoutineTypeContextProviderProps {
    children: ReactNode;
}

export interface RoutineTypeContextData {
    getAllRoutineTypePaged: (
        pageNumber: number,
        officeId?: number,
    ) => Promise<RoutineTypeType[] | null>;
    setRoutineType: any;
    routineType: RoutineTypeType;
    setListRoutineType: any;
    listRoutineType: RoutineTypeType[];
    setInitialListRoutineType: any;
    initialListRoutineType: RoutineTypeType[];
    setPageCount: any;
    pageCount: number;
    createRoutineType: (createRoutineTypeType: CreateRoutineTypeType) => Promise<CreateRoutineTypeType | null>;
    updateRoutineType: (updateRoutineTypeType: UpdateRoutineTypeType) => Promise<UpdateRoutineTypeType | null>;
    deleteRoutineType: (id: number) => Promise<RoutineTypeType | null>;
}

const initialState: RoutineTypeContextData = {
    getAllRoutineTypePaged: async (
        pageNumber: number,
        officeId?: number,
    ) => {
        return Promise.resolve(null);
    },
    setRoutineType: () => { },
    routineType: {
        id: 0,
        type: "",
        office: {
            id: 0,
            name: "",
        },
    },
    setListRoutineType: () => {},
    listRoutineType: [],
    setInitialListRoutineType: () => {},
    initialListRoutineType: [],
    setPageCount: () => {},
    pageCount: 0,
    createRoutineType: async (createRoutineTypeType: CreateRoutineTypeType) => {
        return Promise.resolve(null);
    },
    updateRoutineType: async (updateRoutineTypeType: UpdateRoutineTypeType) => {
        return Promise.resolve(null);
    },
    deleteRoutineType: async (id: number) => {
        return Promise.resolve(null);
    },
};

export const RoutineTypeContext =
    createContext<RoutineTypeContextData>(initialState);

export default function RoutineTypeContextProvider({
    children,
}: RoutineTypeContextProviderProps) {
    const {
        getAllRoutineTypePaged,
        createRoutineType,
        updateRoutineType,
        deleteRoutineType,
        setInitialListRoutineType,
        initialListRoutineType,
        setListRoutineType,
        listRoutineType,
        setRoutineType,
        routineType,
        setPageCount,
        pageCount,
    } = useRoutineType();

    return (
        <RoutineTypeContext.Provider
            value={{
                getAllRoutineTypePaged,
                createRoutineType,
                updateRoutineType,
                deleteRoutineType,
                setInitialListRoutineType,
                initialListRoutineType,
                setListRoutineType,
                listRoutineType,
                setRoutineType,
                routineType,
                setPageCount,
                pageCount,
            }}
        >
            {children}
        </RoutineTypeContext.Provider>
    );
}