import { ReactNode, createContext } from "react";
import useDownload from "./hooks/useDownload";

interface DownloadContextProviderProps {
    children: ReactNode;
}

export interface DownloadContextData {
    handleDownloadAll(officeId: number, routineId: number): Promise<void>;
    handleDownloadByFilename(filename: string, routineId: number): Promise<void>;
    handleDownloadByTaxTasksId(officeId: number, taxTasksId: number): Promise<void>;
}

const initialState: DownloadContextData = {
    handleDownloadAll: async () => {},
    handleDownloadByFilename: async () => {},
    handleDownloadByTaxTasksId: async () => {},
};

export const DownloadContext = createContext<DownloadContextData>(initialState);

export default function DownloadContextProvider({
    children,
}: DownloadContextProviderProps) {
    const { handleDownloadAll, handleDownloadByFilename, handleDownloadByTaxTasksId } = useDownload();

    return (
        <DownloadContext.Provider
            value={{
                handleDownloadAll,
                handleDownloadByFilename,
                handleDownloadByTaxTasksId,
            }}
        >
            {children}
        </DownloadContext.Provider>
    );
}