import React, { useContext, useEffect } from 'react';
import { Container } from "reactstrap";
import Header from "../components/Headers/Header";
import AdminNavbar from "../components/Navbars/AdminNavbar/AdminNavbar";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import WhatsappButton from "../components/WhatsappButtom/WhatsappButtom";
import { AuthContext } from '../context/AuthContext'; 
import TableOffice from "../components/TableOffice/TableOffice"; // Importando o TableCustomer

const OfficePage = () => {
  const { objUserTk } = useContext(AuthContext); 

  useEffect(() => {
    // Aqui você pode adicionar lógica de efeito se necessário
  }, []);

  return (
    <>
      {/* Botão do WhatsApp e Spinner de Carregamento */}
      <WhatsappButton />
      <LoadingSpinner />
      
      {/* Cabeçalho da Página */}
      <Header />
      
      {/* Barra de Navegação */}
      <AdminNavbar 
        brandText={"Empresas"}
        search={false}
        titleSearch={""}
      />

      {/* Conteúdo da Tabela de Clientes */}
      <Container className="mt-4">
        <TableOffice />
      </Container>
    </>
  );
};

export default OfficePage;
