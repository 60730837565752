import { ReactNode, createContext } from "react";
import useOffice, { OfficeType, UpdateOfficeType } from "./hooks/useOffice";

interface OfficeContextProviderProps {
    children: ReactNode;
}

export interface OfficeContextData {
    office: OfficeType;
    setOffice: any;
    updateOffice: (updateOfficeData: UpdateOfficeType) => Promise<UpdateOfficeType | null>;
    handleGetByUserId: (userId: number) => Promise<OfficeType | null>;
    handleGetOfficeByOfficeId: (officeId: number) => Promise<OfficeType | null>;
}

const initialState: OfficeContextData = {
    office: {
        name: "",
        email: "",
        id: 0,
        cnpj: "",
        area: "",
        photo: "",
        smtpHost: "",
        smtpPort: 0,
        enableSsl: false,
        smtpUserName: "",
        smtpPassword: "",
        user: {
            name: "",
            email: "",
            password: "",
            type: 0,
        },
    },
    setOffice: () => { },
    updateOffice: async () => null,
    handleGetByUserId: async () => null,
    handleGetOfficeByOfficeId: async () => null,
};

export const OfficeContext =
    createContext<OfficeContextData>(initialState);

export default function OfficeContextProvider({
    children,
}: OfficeContextProviderProps) {
    const {
        office,
        setOffice,
        updateOffice,
        handleGetByUserId,
        handleGetOfficeByOfficeId,
    } = useOffice();

    return (
        <OfficeContext.Provider
            value={{
                office,
                setOffice,
                updateOffice,
                handleGetByUserId,
                handleGetOfficeByOfficeId,
            }}
        >
            {children}
        </OfficeContext.Provider>
    );
}