import { ReactNode, createContext } from "react";
import useUserType, {UserTypeType, CreateUserTypeType, UpdateUserTypeType} from "./hooks/useUserType";

interface UserTypeContextProviderProps {
    children: ReactNode;
}

export interface UserTypeContextData {
    getAllUserTypePaged: (
        pageNumber: number,
        officeId?: number,
    ) => Promise<UserTypeType[] | null>;
    setUserType: any;
    userType: UserTypeType;
    setListUserType: any;
    listUserType: UserTypeType[];
    setInitialListUserType: any;
    initialListUserType: UserTypeType[];
    setPageCount: any;
    pageCount: number;
    createUserType: (createUserTypeType: CreateUserTypeType) => Promise<CreateUserTypeType | null>;
    updateUserType: (updateUserTypeType: UpdateUserTypeType) => Promise<UpdateUserTypeType | null>;
    deleteUserType: (id: number) => Promise<UserTypeType | null>;
}

const initialState: UserTypeContextData = {
    getAllUserTypePaged: async (
        pageNumber: number,
        officeId?: number,
    ) => {
        return Promise.resolve(null);
    },
    setUserType: () => { },
    userType: {
        id: 0,
        type: "",
        office: {
            id: 0,
            name: "",
        },
    },
    setListUserType: () => {},
    listUserType: [],
    setInitialListUserType: () => {},
    initialListUserType: [],
    setPageCount: () => {},
    pageCount: 0,
    createUserType: async (createUserTypeType: CreateUserTypeType) => {
        return Promise.resolve(null);
    },
    updateUserType: async (updateUserTypeType: UpdateUserTypeType) => {
        return Promise.resolve(null);
    },
    deleteUserType: async (id: number) => {
        return Promise.resolve(null);
    },
};

export const UserTypeContext =
    createContext<UserTypeContextData>(initialState);

export default function UserTypeContextProvider({
    children,
}: UserTypeContextProviderProps) {
    const {
        getAllUserTypePaged,
        createUserType,
        updateUserType,
        deleteUserType,
        setInitialListUserType,
        initialListUserType,
        setListUserType,
        listUserType,
        setUserType,
        userType,
        setPageCount,
        pageCount,
    } = useUserType();

    return (
        <UserTypeContext.Provider
            value={{
                getAllUserTypePaged,
                createUserType,
                updateUserType,
                deleteUserType,
                setInitialListUserType,
                initialListUserType,
                setListUserType,
                listUserType,
                setUserType,
                userType,
                setPageCount,
                pageCount,
            }}
        >
            {children}
        </UserTypeContext.Provider>
    );
}