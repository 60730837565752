import { ReactNode, createContext } from "react";
import useUpload, { UploadType } from "./hooks/useUpload";

interface UploadContextProviderProps {
    children: ReactNode;
}

export interface UploadContextData {
    handleUpload(uploadData: UploadType): Promise<UploadType | null>;
}

const initialState: UploadContextData = {
    handleUpload: async () => null,
};

export const UploadContext =
    createContext<UploadContextData>(initialState);

export default function UploadContextProvider({
    children,
}: UploadContextProviderProps) {
    const { handleUpload } = useUpload();

    return (
        <UploadContext.Provider
            value={{
                handleUpload,
            }}
        >
            {children}
        </UploadContext.Provider>
    );
}