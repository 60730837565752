import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface OfficeType {
    id: number;
    name: string;
    email: string;
    password?: string;
    cnpj?: string;
    area?: string;
    photo?: string;
    smtpHost?: string;
    smtpPort?: number;
    enableSsl?: boolean;
    smtpUserName?: string;
    smtpPassword?: string;
    user: {
        name: string;
        email: string;
        password: string;
        type: number;
    };
}

export interface UpdateOfficeType {
    id: number;
    name: string;
    email: string;
    cnpj: string;
    area: string;
    photo: string;
    password: string;
    smtpHost?: string;
    smtpPort?: number;
    enableSsl?: boolean;
    smtpUserName?: string;
    smtpPassword?: string;
}

export default function useOffice() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext)
    const { handleLogout, objUserTk } = useContext(AuthContext)
    const [office, setOffice] = useState<OfficeType>({
        name: "",
        email: "",
        id: 0,
        cnpj: "",
        area: "",
        photo: "",
        smtpHost: "",
        smtpPort: 0,
        enableSsl: false,
        smtpUserName: "",
        smtpPassword: "",
        user: {
            name: "",
            email: "",
            password: "",
            type: 0,
        },
    });

    function updateOffice(updateOfficeData: UpdateOfficeType): Promise<UpdateOfficeType | null> {
        setLoading(true);
        return api
            .put(`/Office/${updateOfficeData.id}`, {
                name: updateOfficeData.name,
                email: updateOfficeData.email,
                passwork: updateOfficeData.password,
                cnpj: updateOfficeData.cnpj,
                area: updateOfficeData.area,
                photo: updateOfficeData.photo,
                smtpHost: updateOfficeData.smtpHost,
                smtpPort: updateOfficeData.smtpPort,
                enableSsl: updateOfficeData.enableSsl,
                smtpUserName: updateOfficeData.smtpUserName,
                smtpPassword: updateOfficeData.smtpPassword,
            })
            .then((response) => {
                showToast("success", "Escritório atualizado com sucesso.");
                setLoading(false);
                return null;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao atualizar escritório. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao atualizar escritório:", error);
                return null;
            });
    }

    function handleGetByUserId(id: number): Promise<OfficeType | null> {
        setLoading(true);
        return api
            .get(`/Office/getbyuserid/${id}`)
            .then((response) => {
                const office: OfficeType = response.data;
                setOffice(office);
                setLoading(false);
                return office;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter informações do escritório. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao obter informações do escritório:", error);
                return null;
            });
    }

    function handleGetOfficeByOfficeId(id: number): Promise<OfficeType | null> {
        setLoading(true);
        return api
            .get(`/Office/getbyofficeid/${id}`)
            .then((response) => {
                const office: OfficeType = response.data;
                setOffice(office);
                setLoading(false);
                return office;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter informações do escritório. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao obter informações do escritório:", error);
                return null;
            });
    }

    return { 
        office, 
        setOffice,
        updateOffice, 
        handleGetByUserId,
        handleGetOfficeByOfficeId
    };
}
