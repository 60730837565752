import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          
        </Col>

        <Col xl="6">
          <Nav className="nav-footer justify-content-center justify-content-xl-end d-flex align-items-baseline">
            <NavItem>
              <NavLink href="" rel="noopener noreferrer" target="_blank">
                Contato
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="" rel="noopener noreferrer" target="_blank">
                <img
                  style={{ width: "147px" }}
                  alt="..."
                  src={require("../../assets/img/brand/logoAtt.png")}
                />
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
