import Login from "./views/Login.tsx";
import Home from "./views/Home.tsx";
import Register from "./views/Register.tsx";
import ForgotPassword from "./views/ForgotPassword.tsx";
import Customer from "./views/screensOffice/Customer.tsx";
import OfficePage from "./views/OfficePage.tsx";
import Routine from "./views/screensOffice/Routine.tsx";
import CustomerRoutine from "./views/screensCustomer/CustomerRoutine.tsx";
import RoutineCustomerPage from "./views/screensCustomer/RoutineCustomerPage.tsx";
import OfficeProfile from "./views/screensOffice/Profile.tsx";
import TaxTask from "./views/screensOffice/TaxTasks.tsx";
import RoutineType from "./views/screensOffice/RoutineType.tsx";
import UserType from "./views/screensOffice/UserType.tsx";
import AddUser from "./views/screensOffice/AddUser.tsx";

export const routes = [
  {
    path: "/index",
    name: "Página Inicial",
    icon: "fa fa-home text-yellow",
    component: <Home />,
    layout: "/admin",
  },
  {
    path: "/register",
    name: "Cadastrar Escritório",
    icon: "fa-solid fa-building text-orange",
    component: <Register />,
    layout: "/auth",
  },
  {
    path: "/login",
    name: "Sair",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    name: "Esqueceu a Senha",
    icon: "ni ni-lock-circle-open text-info",
    component: <ForgotPassword />,
    layout: "/auth",
  },
];

export const routesOffice = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "fa fa-home text-gray",
    component: <Home />,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Meus dados",
    icon: "fa-regular fa-address-card text-gray",
    component: <OfficeProfile />,
    layout: "/admin",
  },
  {
    path: "/table-user-type",
    name: "Tipos de Usuário",
    icon: "fa-solid fa-user text-blue",
    component: <UserType />,
    layout: "/admin",
  },
  {
    path: "/table-user",
    name: "Usuários da contabilidade",
    icon: "fa-solid fa-user text-gray",
    component: <AddUser />,
    layout: "/admin",
  },
  {
    path: "/table-customer",
    name: "Clientes",
    icon: "fa fa-users text-gray",
    component: <Customer />,
    layout: "/admin",
  },
  {
    path: "/table-routine-type",
    name: "Tipos de Rotina",
    icon: "fa-solid fa-gear text-blue",
    component: <RoutineType />,
    layout: "/admin",
  },
  {
    path: "/table-routine",
    name: "Rotina",
    icon: "fa-solid fa-gear text-gray",
    component: <Routine />,
    layout: "/admin",
  },
  {
    path: "/taxTasks",
    name: "Gerenciar Tarefas",
    icon: "fa fa-tasks text-gray",
    component: <TaxTask />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Sair",
    icon: "ni ni-key-25 text-gray",
    component: <Login />,
    layout: "/auth",
  },
];

export const routesCustomer = [
  {
    path: "/index",
    name: "Página Inicial",
    icon: "fa fa-home text-gray",
    component: <Home />,
    layout: "/admin",
  },
  {
    path: "/table-customer-routine",
    name: "Gerenciar Tarefas",
    icon: "fa fa-tasks text-gray",
    component: <CustomerRoutine />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Sair",
    icon: "ni ni-key-25 text-gray",
    component: <Login />,
    layout: "/auth",
  },
];

export const routesAdmin = [
  {
    path: "/table-customer",
    name: "Clientes",
    icon: "fa fa-users text-blue",
    component: <Customer />,
    layout: "/admin",
  },
  {
    path: "/table-office",
    name: "Escritórios",
    icon: "fa fa-building text-green",
    component: <OfficePage />,
    layout: "/admin",
  },
  {
    path: "/table-routine",
    name: "Rotinas",
    icon: "fa fa-tasks text-purple",
    component: <Routine />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Sair",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
];
