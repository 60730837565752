import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { FavoriteContext } from "../FavoriteContext";
import {jwtDecode} from "jwt-decode";

export interface UserProps {
  password: string;
  email: string;
}

export interface FormPassword {
  password: string;
  confirmPassword: string;
}

export interface Office {
  name: string;
  email: string;
  password: string;
  cnpj: string;
  area: string;
  photo: string;
  user: {
    id: number;
    createdDate: string;
    updatedDate: string;
    name: string;
    email: string;
    password: string;
    type: number;
  };
}

export interface UserToken {
  unique_name: string;
  UserName: string;
  UserId: number;
  Type: "Customer" | "Office";
  OfficeId: number;
}

type UserTypeCheck = 1 | 2 | 3;

export default function useAuth() {
  const { handleGetByUserId } = useContext(FavoriteContext);
  const [data, setData] = useState<UserProps>({ password: "", email: "" });
  const [formPassword, setFormPassword] = useState<FormPassword>({ password: "", confirmPassword: "" });
  const [authenticated, setAuthenticated] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const { setLoading } = useContext(LoadingContext);
  const tokenLocal = localStorage.getItem("token");

  let objUserTk: UserToken;
  if (tokenLocal) {
    objUserTk = jwtDecode(tokenLocal) as UserToken; 
  } else {
    objUserTk = {} as UserToken;
  }

  const [selectTypeUser, setSelectTypeUser] = useState<UserTypeCheck>(
    parseInt(objUserTk.Type) as UserTypeCheck || 1
  );

  const navigate = useNavigate();
  const showToast = useToast();

  function handleSetUser({ password, email }: UserProps) {
    setData({ password, email });
  }

  async function officeRegister(officeCreateRequest: Office) {
    try {
      const headers = { Authorization: `Bearer ${tokenLocal}` };
      await api.post("/Office/create", officeCreateRequest, { headers });
      showToast("success", "Escritório cadastrado com sucesso!");
      navigate("/auth/login");
    } catch (error) {
      showToast("error", "Erro ao cadastrar escritório. Tente novamente.");
    }
  }

  async function handleLogin({ email, password }: UserProps) {
    try {
      let userType = selectTypeUser;
      const isAdmin = email === "Admin@gmail.com" && password === "root";
      if (isAdmin) {
        setSelectTypeUser(3);
      }

      const {
        data: { token },
      } = await api.post("/Users/authenticate", { email, password, type: userType });
      localStorage.setItem("token", token);
      api.defaults.headers.Authorization = `Bearer ${token}`;
      setAuthenticated(true);
      console.log(objUserTk?.OfficeId);
      navigate("/admin/index");
    } catch (error) {
      showToast("error", "Erro ao efetuar o login. Verifique suas credenciais.");
    }
  }

  async function handleChangePassword(data: FormPassword) {
    try {
      setLoading(true);
      await api.put(`/Users/update-password/${objUserTk.UserId}`, data);
      showToast("success", "Senha atualizada com sucesso.");
    } catch (error) {
      showToast("error", "Erro ao mudar a senha. Verifique novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  }

  async function sendResetPasswordEmail(email: string) {
    try {
      await api.post("/Users/reset-password", { email });
      showToast("success", "E-mail de redefinição de senha enviado.");
    } catch (error) {
      showToast("error", "Erro ao enviar o e-mail de redefinição de senha.");
    }
  }

  async function verifyResetCode(email: string, code: string): Promise<boolean> {
    try {
      const response = await api.post("/Users/verify-reset-code", { email, code });
      return response.data.isValid;
    } catch (error) {
      console.error("Erro ao verificar código de redefinição:", error);
      return false;
    }
  }

  async function handleLogout() {
    setAuthenticated(false);
    localStorage.removeItem("token");
    api.defaults.headers.Authorization = null;
    navigate("/auth/login");
  }

  useEffect(() => {
    if (tokenLocal) {
      api.defaults.headers.Authorization = `Bearer ${tokenLocal}`;
      setAuthenticated(true);
    }
    setLoadingButton(false);

    if (objUserTk?.Type === "Customer") {
      setSelectTypeUser(2);
    }
  }, [tokenLocal]);

  return {
    authenticated,
    loadingButton,
    officeRegister,
    handleLogin,
    handleLogout,
    handleSetUser,
    data,
    objUserTk,
    selectTypeUser,
    setSelectTypeUser,
    formPassword,
    setFormPassword,
    handleChangePassword,
    sendResetPasswordEmail,
    verifyResetCode,
  };
}
