import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import UserHeader from "../../components/Headers/UserHeader/UserHeader";
import AdminNavbar from "../../components/Navbars/AdminNavbar/AdminNavbar";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { OfficeContext } from "../../context/OfficeContext";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ModalChangePassword from "../../components/ModalChangePassword/ModalChangePassword";
import useUser, { UpdateUserType } from "../../context/hooks/useUser";

const ProfileRecruiter = () => {
  const { handleGetOfficeByOfficeId } = useContext(OfficeContext);
  const { handleGetByOfficeId, updateUser, user, setUser, handleGetByOfficeIdAndUserId } = useUser();
  const { objUserTk } = useContext(AuthContext);

  const [editPerfilState, setEditPerfilState] = useState<boolean>(true);
  const photoInputRef = useRef<HTMLInputElement>(null);
  const [openModalChangePassword, setOpenModalChangePassword] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    if (user.office && name in user.office) {
      setUser((prevUser) => ({
        ...prevUser,
        office: {
          ...prevUser.office,
          [name]: type === "checkbox" ? checked : value,
        },
      }));
    } else {
      setUser((prevUser) => ({
        ...prevUser,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSalveIndoOffice = async (update: UpdateUserType) => {
    const updateData: UpdateUserType = {
      id: Number(objUserTk.UserId),
      name: update.name,
      email: update.email,
      password: "",
      office: {
        id: Number(objUserTk.OfficeId),
        name: update.office.name,
        email: update.office.email,
        cnpj: update.office.cnpj,
        area: update.office.area,
        photo: update.office.photo,
        smtpHost: update.office.smtpHost,
        smtpPort: update.office.smtpPort,
        enableSsl: update.office.enableSsl,
        smtpUserName: update.office.smtpUserName,
        smtpPassword: update.office.smtpPassword,
        templateTarefasAntesDoVencimento: update.office.templateTarefasAntesDoVencimento,
        templateTarefasEmDia: update.office.templateTarefasEmDia,
        templateTarefasAtrasadas: update.office.templateTarefasAtrasadas,
      }
    };
    await updateUser(updateData);
    handleGetByOfficeIdAndUserId(objUserTk.OfficeId, objUserTk.UserId);
    handleGetOfficeByOfficeId(objUserTk.OfficeId);
    setEditPerfilState(true);
  };

  const handleClickChangePhoto = () => {
    if (photoInputRef.current) {
      photoInputRef.current.click();
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const contentBase64 = e.target?.result as string;
        if (file.type.startsWith("image/")) {
          setUser((prevUser) => ({
            ...prevUser,
            office: {
              ...prevUser.office,
              photo: contentBase64,
            },
          }));
        } else {
          alert("Tipo de arquivo não suportado:");
        }
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleGetByOfficeIdAndUserId(objUserTk.OfficeId, objUserTk.UserId);
      await handleGetOfficeByOfficeId(objUserTk.OfficeId);
    };

    fetchData();
  }, []);

  return (
    <>
      <UserHeader />
      <LoadingSpinner />
      <AdminNavbar brandText={"MEU PERFIL"} search={false} titleSearch={""} />
      <Container className="mt--3" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-end">
                <Col className="order-lg-2" lg="6">
                  <div className="card-profile-image">
                    <div className="card-candidate-content">
                      <div className="card-profile-image-recruiter ">
                        <img
                          style={{
                            objectFit: "cover",
                            objectPosition: "center center",
                          }}
                          alt="Avatar"
                          className="img-fluid rounded-circle"
                          src={
                            user.office.photo ||
                            require("../../assets/img/theme/profile.png")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <div className="d-flex justify-content-between">
                  <label className="fileInput">
                    {!editPerfilState && (
                      <Button

                        style={{ color: "#FFF", backgroundColor: "#585858" }}
                        size="sm"
                        onClick={handleClickChangePhoto}
                      >
                        Mudar foto
                      </Button>
                    )}
                  </label>
                  <input
                    ref={photoInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3>{user?.name}</h3>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <div className="col-6 xs-8">
                    <h3 className="mb-0">Minha conta</h3>
                  </div>
                  <Col className="text-right col-6" xs="4">
                    <Button
                      style={{ color: "#FFF", backgroundColor: "#585858" }}
                      onClick={(e) => setOpenModalChangePassword(true)}
                      size="sm"
                    >
                      Mudar senha
                    </Button>
                    {editPerfilState ? (
                      <>
                        <Button
                          style={{ color: "#FFF", backgroundColor: "#585858" }}
                          onClick={(e) => setEditPerfilState(false)}
                          size="sm"
                        >
                          Editar seu perfil
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          style={{ color: "#FFF", backgroundColor: "#585858" }}
                          onClick={() => handleSalveIndoOffice(user)}
                          size="sm"
                        >
                          Salvar edições
                        </Button>
                        <Button
                          style={{
                            color: "#585858",
                            border: " 1px solid #585858",
                          }}
                          onClick={() => setEditPerfilState(true)}
                          size="sm"
                        >
                          Cancelar
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    INFORMAÇÃO DA CONTABILIDADE
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Nome completo
                          </label>
                          <Input
                            name="name"
                            value={user?.name}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="Nome"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email Corporativo
                          </label>
                          <Input
                            name="email"
                            value={user?.email}
                            onChange={handleChange}
                            disabled
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="exemple@example.com"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cnpj"
                          >
                            CNPJ
                          </label>
                          <Input
                            name="cnpj"
                            value={user?.office.cnpj}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-cnpj"
                            placeholder="CNPJ"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-area"
                          >
                            Área
                          </label>
                          <Input
                            name="area"
                            value={user?.office.area}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-area"
                            placeholder="Área"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <h6 className="heading-small text-muted mb-4">
                    CONFIGURAÇÃO DO ENVIO DE EMAIL
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="select-smtp-host">
                            Provedor de Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            type="select"
                            name="smtpHost"
                            id="select-smtp-host"
                            onChange={handleChange}
                            disabled={editPerfilState}
                            value={user.office.smtpHost}
                          >
                            <option value="">Selecione um provedor</option>
                            <option value="smtp.gmail.com">Gmail</option>
                            <option value="smtp-mail.outlook.com">Outlook/Hotmail</option>
                            <option value="smtp.mail.yahoo.com">Yahoo</option>
                            <option value="smtp.zoho.com">Zoho</option>
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-smtp-host">
                            SMTP Host
                          </label>
                          <Input
                            name="smtpHost"
                            value={user.office.smtpHost}
                            disabled
                            className="form-control-alternative"
                            id="input-smtp-host"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="select-smtp-port">
                            Porta SMTP
                          </label>
                          <Input
                            className="form-control-alternative"
                            type="select"
                            name="smtpPort"
                            id="select-smtp-port"
                            onChange={handleChange}
                            disabled={editPerfilState}
                            value={user.office.smtpPort}
                          >
                            <option value="">Selecione uma porta</option>
                            <option value="465">465 (SSL)</option>
                            <option value="587">587 (STARTTLS)</option>
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col lg="6" className="d-flex align-items-center">
                        <FormGroup check className="d-flex align-items-center">
                          <label className="form-control-label mr-5 mb-0" htmlFor="enable-ssl">
                            SSL Ativado
                          </label>
                          <Input
                            type="checkbox"
                            name="enableSsl"
                            checked={user.office.enableSsl}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            id="enable-ssl"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-smtp-username">
                            Nome de Acesso SMTP
                          </label>
                          <Input
                            name="smtpUserName"
                            value={user.office.smtpUserName}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-smtp-username"
                            placeholder="exemplo@example.com"
                            type="email"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-smtp-password">
                            Senha SMTP
                          </label>
                          <Input
                            name="smtpPassword"
                            value={user.office.smtpPassword}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-smtp-password"
                            placeholder="Senha"
                            type="password"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <h6 className="heading-small text-muted mb-4">
                    CONFIGURAÇÃO DOS TEMPLATES DE EMAIL
                  </h6>
                  <div className="pl-lg-4">
                  <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-tasks-to-be-completed-template"
                          >
                            Template para Tarefas antes do Vencimento
                          </label>
                          <Input
                            name="templateTarefasAntesDoVencimento"
                            value={user.office.templateTarefasAntesDoVencimento}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-tasks-to-be-completed-template"
                            placeholder="Template de Tarefas a serem Completadas"
                            type="textarea"
                            rows="6"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-tasks-to-be-completed-template"
                          >
                            Template para Tarefas a serem Completadas
                          </label>
                          <Input
                            name="templateTarefasEmDia"
                            value={user.office.templateTarefasEmDia}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-tasks-to-be-completed-template"
                            placeholder="Template de Tarefas a serem Completadas"
                            type="textarea"
                            rows="6"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-overdue-tasks-template"
                          >
                            Template para Tarefas Vencidas
                          </label>
                          <Input
                            name="templateTarefasAtrasadas"
                            value={user.office.templateTarefasAtrasadas}
                            onChange={handleChange}
                            disabled={editPerfilState}
                            className="form-control-alternative"
                            id="input-overdue-tasks-template"
                            placeholder="Template de Tarefas Vencidas"
                            type="textarea"
                            rows="6"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container >
      <ModalChangePassword
        openModal={openModalChangePassword}
        closeModal={() => setOpenModalChangePassword(false)}
      />
    </>
  );
};

export default ProfileRecruiter;