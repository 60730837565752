import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, Button, ListGroup, ListGroupItem } from 'reactstrap';

interface MultipleFileUploadWithPreviewProps {
    onFilesSelected: (files: File[]) => void;
}

const MultipleFileUploadWithPreview: React.FC<MultipleFileUploadWithPreviewProps> = ({ onFilesSelected }) => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const filesArray = Array.from(e.target.files);
            setSelectedFiles(prevFiles => [...prevFiles, ...filesArray]);
            onFilesSelected([...selectedFiles, ...filesArray]);
        }
    };

    const handleRemoveFile = (index: number) => {
        const newFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(newFiles);
        onFilesSelected(newFiles);
    };

    return (
        <Form>
            <FormGroup>
                <Label for="files">Selecione os Arquivos</Label>
                <Input
                    type="file"
                    name="files"
                    multiple
                    onChange={handleFileChange}
                />
            </FormGroup>
            <ListGroup>
                {selectedFiles.map((file, index) => (
                    <ListGroupItem key={index} className="d-flex justify-content-between align-items-center">
                        {file.name}
                        <Button color="danger" size="sm" onClick={() => handleRemoveFile(index)}>Remover</Button>
                    </ListGroupItem>
                ))}
            </ListGroup>
        </Form>
    );
};

export default MultipleFileUploadWithPreview;