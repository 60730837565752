import {
  Button,
  Card,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useContext, ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { useToast } from "../context/ToastContext";

const ForgotPassword = () => {
  const { sendResetPasswordEmail, verifyResetCode } = useContext(AuthContext);
  const showToast = useToast();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleResetPasswordClick = async () => {
    setLoading(true);
    try {
      await sendResetPasswordEmail(email);
      showToast("success", "Check your email for the verification code.");
      setModalOpen(true);
    } catch (error) {
      showToast("error", "Failed to send reset password email. Try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerificationCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(event.target.value);
  };

  const handleVerifyCodeClick = async () => {
    setIsVerifying(true);
    try {
      const isValid = await verifyResetCode(email, verificationCode);
      if (isValid) {
        navigate("/auth/reset-password");
      } else {
        showToast("error", "Invalid verification code. Please try again.");
      }
    } catch (error) {
      showToast("error", "Error verifying the code. Try again later.");
    } finally {
      setIsVerifying(false);
    }
  };

  return (
    <Col lg="5" md="7">
      <Card className="shadow-lg border-0 p-4" style={{ borderRadius: "20px", backgroundColor: "#f0f2f5" }}>
        <img
          style={{ width: "63%" }}
          alt="Logo"
          className="rounded-circle mx-auto d-block mt-3 mb-4"
          src={require("../assets/img/brand/logoAtt.png")}
        />
        <div className="px-lg-5">
          <div className="text-center text-muted mb-4">
            <small>Forgot your password? Enter your email to reset it.</small>
          </div>
          <Form role="form">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  style={{ backgroundColor: "#fff", borderRadius: "10px" }}
                />
              </InputGroup>
            </FormGroup>
            <div className="text-center">
              <Button
                onClick={handleResetPasswordClick}
                color="secondary"
                disabled={loading}
                style={{ backgroundColor: "#6c757d", color: "#FFF", borderRadius: "12px" }}
              >
                {loading && <Spinner size="sm" />} Send Reset Link
              </Button>
            </div>
          </Form>
        </div>
      </Card>
      <Row className="mt-3">
        <Col xs="12" className="text-center">
          <a
            className="text-muted"
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              navigate("/auth/login");
            }}
          >
            <small>Back to Login</small>
          </a>
        </Col>
      </Row>
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Enter Verification Code</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input
              type="text"
              name="verificationCode"
              placeholder="Verification Code"
              value={verificationCode}
              onChange={handleVerificationCodeChange}
              style={{ borderRadius: "10px" }}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={handleVerifyCodeClick}
            disabled={isVerifying}
            style={{ backgroundColor: "#6c757d", color: "#FFF", borderRadius: "12px" }}
          >
            {isVerifying && <Spinner size="sm" />} Verify Code
          </Button>
          <Button color="secondary" onClick={() => setModalOpen(false)} style={{ borderRadius: "12px" }}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Col>
  );
};

export default ForgotPassword;
