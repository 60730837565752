import React, { useContext } from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import AdminFooter from "../../components/Footers/AdminFooter";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./Admin.css";
import { routesAdmin, routesOffice, routesCustomer } from "../../routes.js";
import { AuthContext } from "../../context/AuthContext";
import { OfficeContext } from "../../context/OfficeContext";
import useUser from "../../context/hooks/useUser";

const Admin = (props: any) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const { selectTypeUser } = useContext(AuthContext);
  const { office } = useContext(OfficeContext);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document && document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
    if (mainContent && mainContent.current) {
      //@ts-ignore
      mainContent.current.scrollTop = 0;
    }
  }, [location]);

  const getRoutes = (routes: any) => {
    return routes.map((prop: any, key: number) => {
      if (prop.layout === "/admin") {
        return (
          // @ts-ignore
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  // Determina quais rotas usar com base no tipo de usuário
  const userRoutes =
    selectTypeUser === 2
      ? routesCustomer
      : selectTypeUser === 3
      ? routesAdmin
      : routesOffice;

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={userRoutes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: office.photo || require("../../assets/img/brand/logoAtt.png"),
          imgAlt: "User Photo",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <Routes>
          {getRoutes(userRoutes)}
          <Route path="*" element={<Navigate to="/admin/index" replace />} />
        </Routes>
        <AdminFooter />
      </div>
    </div>
  );
};

export default Admin;
