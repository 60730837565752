import { useContext } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface UploadType {
    taxTaskId: number;
    officeId: number;
    fileName: string;
    files?: File[];
}

export default function useUpload() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const { handleLogout, objUserTk } = useContext(AuthContext);

    function handleUpload(uploadData: UploadType): Promise<UploadType | null> {
        const formData = new FormData();
        formData.append("taxTaskId", uploadData.taxTaskId.toString());
        formData.append("officeId", uploadData.officeId.toString());
        formData.append("fileName", uploadData.fileName);

        if (uploadData.files && uploadData.files.length > 0) {
            uploadData.files.forEach((file) => {
                formData.append("files", file);
            });
        } else {
            const nullFile = new File([""], "null");
            formData.append("files", nullFile);
        }

        console.log("formData contents:");
        formData.forEach((value, key) => {
            console.log(`${key}: ${value}`);
        });

        setLoading(true);
        return api
            .post(`/Upload/Upload`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                showToast("success", "Arquivo enviado com sucesso.");
                setLoading(false);
                return null;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao enviar arquivo. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao enviar arquivo:", error);
                return null;
            });
    }

    return {
        handleUpload,
    };
}
