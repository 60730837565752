import React, { useState, useEffect, useContext } from 'react';
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { Bar, Radar } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import './Dashboard.css';
import { format, parseISO } from 'date-fns';

type TaskType = {
  id: number;
  observation: string;
  shippingDate: string;
  submissionDate: string | null;
  workerDownloadDate: string | null;
  hasDocument: boolean;
  status: number;
  razaoSocial: string;
  description: string;
  tags: string[];
};

type RoutineType = {
  id: number;
  type: string;
  office: {
    name: string;
    createdDate: string;
  };
};

const Dashboard = () => {
  const { objUserTk } = useContext(AuthContext);
  const [tasks, setTasks] = useState<TaskType[]>([]);
  const [routines, setRoutines] = useState<RoutineType[]>([]);
  const [taskStats, setTaskStats] = useState({
    open: 0,
    approved: 0,
    rejected: 0,
  });

  useEffect(() => {
    fetchTasks();
    fetchRoutines();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/TaxTasks/getAllisTaxTasksPaged`,
        {
          params: {
            pageNumber: 1,
            pageSize: 9,
            officeId: objUserTk.OfficeId,
            routineId: 0,
          },
        }
      );
      if (response.status === 200) {
        const taskData: TaskType[] = response.data.result;
        setTasks(taskData);
        calculateTaskStats(taskData);
      } else {
        toast.error('Erro ao buscar tarefas.');
      }
    } catch (error) {
      console.error('Erro ao buscar tarefas:', error);
      toast.error('Erro ao buscar tarefas.');
    }
  };

  const fetchRoutines = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/RoutineType/getAllRoutineTypePaged`,
        {
          params: {
            OfficeId: objUserTk.OfficeId,
            PageNumber: 1,
            PageSize: 10,
          },
        }
      );
      if (response.status === 200) {
        setRoutines(response.data.result);
      } else {
        toast.error('Erro ao buscar rotinas.');
      }
    } catch (error) {
      console.error('Erro ao buscar rotinas:', error);
      toast.error('Erro ao buscar rotinas.');
    }
  };

  const formatDateTime = (dateString: string) => {
    if (!dateString) return 'Data não disponível';
    const date = parseISO(dateString);
    return dateString.includes('T')
        ? format(date, 'dd/MM/yyyy HH:mm')
        : format(date, 'dd/MM/yyyy');
};

  const calculateTaskStats = (tasks: TaskType[]) => {
    const open = tasks.filter((task) => task.status === 1).length;
    const approved = tasks.filter((task) => task.status === 5).length;
    const rejected = tasks.filter((task) => task.status === 4).length;

    setTaskStats({ open, approved, rejected });
  };

  const taskDataByMonth = {
    labels: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    datasets: [
      {
        label: 'Tarefas por Mês',
        data: Array(12).fill(0).map((_, i) =>
          tasks.filter(
            (task) =>
              new Date(task.shippingDate).getMonth() === i
          ).length
        ),
        backgroundColor: [
          '#1abc9c', '#3498db', '#9b59b6', '#e74c3c', '#34495e', '#f1c40f',
          '#e67e22', '#2ecc71', '#d35400', '#7f8c8d', '#2980b9', '#8e44ad',
        ],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Container className="dashboard-container">
      <Row className="mb-4">
        <Col lg="4">
          <Card className="task-card">
            <CardBody className="text-center">
              <h5 className="card-title text-muted">Tarefas em Aberto</h5>
              <h3 className="task-count text-primary">{taskStats.open}</h3>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card className="task-card">
            <CardBody className="text-center">
              <h5 className="card-title text-muted">Tarefas Aprovadas</h5>
              <h3 className="task-count text-success">{taskStats.approved}</h3>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card className="task-card">
            <CardBody className="text-center">
              <h5 className="card-title text-muted">Tarefas Reprovadas</h5>
              <h3 className="task-count text-danger">{taskStats.rejected}</h3>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        {/* <Col lg="8">
          <h4 className="section-title text-center">Distribuição de Tarefas por Mês</h4>
          <Bar data={taskDataByMonth} options={{
            plugins: {
              legend: { display: true, position: 'bottom' },
            },
            scales: {
              y: { beginAtZero: true },
            },
          }} />
        </Col> */}
        <Col lg="8">
          <h4 className="section-title text-center">Distribuição de Tarefas por Mês</h4>
          <Radar
            data={{
              labels: taskDataByMonth.labels,
              datasets: [
                {
                  label: 'Tarefas',
                  data: taskDataByMonth.datasets[0].data,
                  backgroundColor: 'rgba(54, 162, 235, 0.2)',
                  borderColor: 'rgba(54, 162, 235, 1)',
                  borderWidth: 2,
                },
              ],
            }}
            options={{
              plugins: {
                legend: { display: true, position: 'bottom' },
              },
              scales: {
                r: { beginAtZero: true },
              },
            }}
          />
        </Col>
        <Col lg="4">
          <h4 className="section-title text-center">Lista de Tarefas</h4>
          <Table hover responsive>
            <thead>
              <tr>
                {/* <th>ID</th> */}
                <th>Descrição</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task) => (
                <tr key={task.id}>
                  {/* <td>{task.id}</td> */}
                  <td>{task.description.length > 20 ? task.description.substring(0, 20) + '...' : task.description}</td>
                  <td>
                    {task.status === 1
                      ? 'Em Aberto'
                      : task.status === 2
                        ? 'Concluído'
                        : task.status === 3
                          ? 'Atrasado'
                          : task.status === 4
                            ? 'Rejeitado'
                            : task.status === 5
                              ? 'Aprovado'
                              : 'Desconhecido'
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col lg="12">
          <h4 className="section-title text-center">Rotinas</h4>
          <Table hover responsive>
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Escritório</th>
                <th>Data de Criação</th>
              </tr>
            </thead>
            <tbody>
              {routines.map((routine) => (
                <tr key={routine.id}>
                  <td>{routine.type}</td>
                  <td>{routine.office.name}</td>
                  <td>{formatDateTime(routine.office.createdDate)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;