import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface RoutineType {
    id: number;
    description: string;
    initialDate: string;
    finalDate: string;
    numberOfCharges: number;
    frequency: string;
    billingFrequency: number;
    // customer: {
    //     razaoSocial: string;
    // };
    // routineType: {
    //     id: number;
    //     type: string;
    // };
    razaoSocial: string;
    type: string;
    routineTypeId: number;
    emails: string[];
    tags: string[];
}

export interface CreateRoutineType {
    description: string;
    initialDate: string;
    finalDate: string | null;
    numberOfCharges: number | null;
    frequency: number;
    billingFrequency: number;
    officeId: number;
    customerId: number;
    routineTypeId: number;
    emails: string[];
    tags: string[];
}

export interface UpdateRoutineType {
    id: number;
    description: string;
    routineTypeId: number;
    emails: string[];
    tags: string[];
}

export default function useRoutine() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const { handleLogout, objUserTk } = useContext(AuthContext);
    const [listRoutine, setListRoutine] = useState<RoutineType[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const [initialListRoutine, setInitialListRoutine] = useState<RoutineType[]>([]);
    const [routine, setRoutine] = useState<RoutineType>({
        id: 0,
        description: "",
        initialDate: "",
        finalDate: "",
        numberOfCharges: 0,
        frequency: "",
        billingFrequency: 0,
        // customer: {
        //     razaoSocial: "",
        // },
        // routineType: {
        //     id: 0,
        //     type: "",
        // },
        razaoSocial: "",
        type: "",
        routineTypeId: 0,
        emails: [],
        tags: []
    });

    function createRoutine(createRoutineType: CreateRoutineType): Promise<CreateRoutineType | null> {
        setLoading(true);
        console.log("createRoutineType", createRoutineType);
        return api
            .post(`/Routine/create`, createRoutineType)
            .then((response) => {
                const createdRoutine: CreateRoutineType = response.data;
                showToast("success", "Rotina criada com sucesso.");
                setLoading(false);
                return createdRoutine;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast("error", "Sessão desconectada. Favor fazer login novamente.", error);
                } else {
                    showToast("error", "Erro ao criar rotina. Verifique novamente mais tarde.", error);
                }
                setLoading(false);
                console.error("Erro ao criar rotina:", error);
                return null;
            });
    }

    function getAllRoutinePaged(
        pageNumber: number,
        officeId?: number,
        customerId?: number,
        userCustomerId?: number,
        description?: string,
        initialDate?: string,
        finalDate?: string,
        frequency?: string,
    ): Promise<RoutineType[] | null> {
        setLoading(true);
        let url = `/Routine/getAllRoutinePaged?pageNumber=${pageNumber}&pageSize=9`;

        if (description) url += `&description=${description}`;
        if (officeId) url += `&officeId=${officeId}&routineId=0`;
        if (customerId) url += `&customerId=${customerId}`;
        if (userCustomerId) url += `&userCustomerId=${userCustomerId}`;
        if (initialDate && finalDate) url += `&initialDate=${initialDate}&finalDate=${finalDate}`;
        if (frequency) url += `&frequency=${frequency}`;

        return api
            .get(url)
            .then((response) => {
                setListRoutine(response.data.result);
                setInitialListRoutine(response.data.result);
                setPageCount(response.data.pageCount);
                setLoading(false);
                return response.data.result;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast("error", "Sessão desconectada. Favor fazer login novamente.", error);
                } else {
                    showToast("error", "Erro ao carregar rotinas. Verifique novamente mais tarde.", error);
                }
                setLoading(false);
                console.error("Erro ao carregar rotinas:", error);
                return null;
            });
    }

    function updateRoutine(updateRoutineData: UpdateRoutineType): Promise<UpdateRoutineType | null> {
        setLoading(true);
        return api
            .put(`/Routine/${updateRoutineData.id}`, updateRoutineData)
            .then((response) => {
                showToast("success", "Rotina atualizada com sucesso.");
                setLoading(false);
                return response.data;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast("error", "Sessão desconectada. Favor fazer login novamente.", error);
                } else {
                    showToast("error", "Erro ao atualizar rotina. Verifique novamente mais tarde.", error);
                }
                setLoading(false);
                console.error("Erro ao atualizar rotina:", error);
                return null;
            });
    }

    function deleteRoutine(routineId: number): Promise<RoutineType | null> {
        setLoading(true);
        return api
            .delete(`/Routine/${routineId}`)
            .then(() => {
                setRoutine({
                    id: 0,
                    description: "",
                    initialDate: "",
                    finalDate: "",
                    numberOfCharges: 0,
                    frequency: "",
                    billingFrequency: 0,
                    // customer: {
                    //     razaoSocial: "",
                    // },
                    // routineType: {
                    //     id: 0,
                    //     type: "",
                    // },
                    razaoSocial: "",
                    type: "",
                    routineTypeId: 0,
                    emails: [],
                    tags: []
                });
                showToast("success", "Rotina deletada com sucesso.");
                setLoading(false);
                return null;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast("error", "Sessão desconectada. Favor fazer login novamente.", error);
                } else {
                    showToast("error", "Erro ao deletar rotina. Verifique novamente mais tarde.", error);
                }
                setLoading(false);
                console.error("Erro ao deletar rotina:", error);
                return null;
            });
    }

    return {
        setInitialListRoutine,
        initialListRoutine,
        setListRoutine,
        listRoutine,
        setRoutine,
        routine,
        setPageCount,
        pageCount,
        createRoutine,
        getAllRoutinePaged,
        updateRoutine,
        deleteRoutine,
    };
}
