import { ReactNode, createContext } from "react";
import useFiles from "./hooks/useFiles";
import { FilesType } from "./hooks/useFiles";

interface FilesContextProviderProps {
    children: ReactNode;
}

export interface FilesContextData {
    listFiles: FilesType[];
    initialListFiles: FilesType[];
    pageCount: number;
    fileName: string;
    Files: FilesType;
    getAllFilesPaged(taxTasksId?: number): Promise<FilesType[] | null>;
    getFileName(taxTasksId?: number): Promise<string | void>;
}

const initialState: FilesContextData = {
    listFiles: [],
    initialListFiles: [],
    pageCount: 0,
    fileName: "",
    Files: {
        id: 0,
        uploadDate: "",
        fileName: "",
        filePath: "",
    },
    getAllFilesPaged: async () => [],
    getFileName: async () => "",
};

export const FilesContext = createContext<FilesContextData>(initialState);

export default function FilesContextProvider({
    children,
}: FilesContextProviderProps) {
    const {
        listFiles,
        initialListFiles,
        pageCount,
        fileName,
        Files,
        getAllFilesPaged,
        getFileName,
    } = useFiles();

    return (
        <FilesContext.Provider
            value={{
                listFiles,
                initialListFiles,
                pageCount,
                fileName,
                Files,
                getAllFilesPaged,
                getFileName,
            }}
        >
            {children}
        </FilesContext.Provider>
    );
}