import React, { useContext, useEffect, useState } from 'react';
import { Container, Table, Button, Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';
import PaginationMaterial from "../../components/PaginationMaterial/PaginationMaterial";
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Header from '../../components/Headers/Header';
import AdminNavbar from '../../components/Navbars/AdminNavbar/AdminNavbar';
import './AddUser.css';
import { AuthContext } from '../../context/AuthContext';
import useUser, { UserType, CreateUserType, UpdateUserType } from '../../context/hooks/useUser';
import { UserTypeContext } from '../../context/UserTypeContext';
import { UserTypeType } from '../../context/hooks/useUserType';

const AddUser = () => {
    const { objUserTk } = useContext(AuthContext);
    const { createUser, getAllUserPaged, updateUser, deleteUser, listUsers, pageCount } = useUser();
    const { listUserType, getAllUserTypePaged } = useContext(UserTypeContext);
    const [newUser, setNewUser] = useState<CreateUserType>({
        name: '',
        email: '',
        password: '',
        userTypeId: 0,
        officeId: objUserTk.OfficeId,
    });

    const [editingUser, setEditingUser] = useState<UpdateUserType | null>(null);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({
        name: '',
    });

    useEffect(() => {
        setLoading(true);
        getAllUserTypePaged(1, objUserTk.OfficeId)
        getAllUserPaged(1, objUserTk.OfficeId)
            .then(data => {
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao buscar tipos de rotinas:", error);
                setLoading(false);
            });
    }, []);

    const onChangePaginationCommon = async (e: any, value: any) => {
        await getAllUserPaged(value, objUserTk.OfficeId);
    };

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSearch = () => {
        setLoading(true);
        getAllUserPaged(
            1,
            objUserTk.OfficeId,
        )
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao aplicar filtros:", error);
                setLoading(false);
            });
    };

    const handleClearFilters = () => {
        getAllUserPaged(
            1,
            objUserTk.OfficeId,
        )
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao liimpar filtros:", error);
                setLoading(false);
            });
    };

    const handleCreateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewUser(prevUser => ({
            ...prevUser,
            [name]: value === '' ? null : value
        }));
    };

    const handleCreateRoutineType = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const payload = {
            ...newUser,
            name: newUser.name,
            email: newUser.email,
            password: newUser.password,
            officeId: Number(newUser.officeId)
        };

        createUser(payload)
            .then(createdUser => {
                if (createdUser) {
                    setCreateModalOpen(false);
                    setNewUser({
                        name: '',
                        email: '',
                        password: '',
                        userTypeId: 0,
                        officeId: objUserTk.OfficeId,
                    });
                    getAllUserPaged(1, objUserTk.OfficeId);
                } else {
                    console.error("Erro ao criar usuário.");
                }
            })
            .catch(error => {
                console.error("Erro ao criar usuário:", error);
            });
    };

    const handleEditUser = (user: UserType) => {
        const updateData: UpdateUserType = {
            id: user.id,
            name: user.name,
            email: user.email,
            password: user.password,
            office: {
                id: user.office.id,
                name: user.office.name,
                email: user.office.email,
                cnpj: user.office.cnpj,
                area: user.office.area,
                photo: user.office.photo,
                smtpHost: user.office.smtpHost,
                smtpPort: user.office.smtpPort,
                enableSsl: user.office.enableSsl,
                smtpUserName: user.office.smtpUserName,
                smtpPassword: user.office.smtpPassword,
                templateTarefasAntesDoVencimento: user.office.templateTarefasAntesDoVencimento,
                templateTarefasEmDia: user.office.templateTarefasEmDia,
                templateTarefasAtrasadas: user.office.templateTarefasAtrasadas,
            },
            userTypeId: user.userType.id,
        };
        setEditingUser(updateData);
        setEditModalOpen(true);
    };

    const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (editingUser) {
            updateUser(editingUser)
                .then(updatedUser => {
                    if (updatedUser) {
                        setEditModalOpen(false);
                        getAllUserPaged(1, objUserTk.OfficeId);
                        setEditingUser(null);
                    } else {
                        console.error("Erro ao atualizar usuário.");
                    }
                })
                .catch(error => {
                    console.error("Erro ao atualizar o usuário:", error);
                });
        }
    };

    const handleDeleteUser = (userId: number) => {
        if (window.confirm("Tem certeza que deseja excluir esse usuário?")) {
            deleteUser(userId)
                .then(deletedUser => {
                    if (deletedUser) {
                        getAllUserPaged(1, objUserTk.OfficeId);
                    } else {
                        console.error("Erro ao excluir usuário.");
                    }
                    getAllUserPaged(1, objUserTk.OfficeId);
                })
                .catch(error => {
                    console.error("Erro ao excluir usuário:", error);
                });
        }
    };

    return (
        <>
            <LoadingSpinner />
            <Header />
            <AdminNavbar
                brandText={"Cadastro de usuário"}
                search={false}
                titleSearch={""}
            />

            <Container className="mt-5">
                <Row>
                    <Col xs="12">
                        {/* <Form className="d-flex search-form">
                            <FormGroup className="mr-3">
                                <Label for="description">Rotina</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="text"
                                    name="description"
                                    id="description"
                                    value={filters.description}
                                    placeholder='Descrição da rotina'
                                    onChange={handleFilterChange}
                                />
                            </FormGroup>
                            <Button className="search-btn" onClick={handleSearch}>Pesquisar</Button>
                            <Button className="clear-btn" onClick={handleClearFilters}>Limpar</Button>
                        </Form> */}
                        <Button
                            style={{ color: "#FFF", backgroundColor: "#585858", float: "left" }}
                            className="btn-filter"
                            onClick={() => setCreateModalOpen(true)}
                        >
                            Adicionar usuário
                        </Button>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <Table hover responsive className="table-striped custom-table">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Email</th>
                                    <th>Contabilidade</th>
                                    <th>Tipo</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listUsers.map((user: UserType, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.office.name}</td>
                                            <td>{user.userType.type}</td>
                                            <td>
                                                <Button onClick={() => { handleEditUser(user); }}><i className="fa-solid fa-pen-to-square"></i></Button>
                                                <Button onClick={() => handleDeleteUser(user.id)} color="danger"><i className="fa-solid fa-trash"></i></Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                            {pageCount && (
                                <div className="d-flex justify-content-center mt-4">
                                    <PaginationMaterial
                                        onChange={(e: any, value: any) => onChangePaginationCommon(e, value)}
                                        count={pageCount}
                                    />
                                </div>
                            )}
                            {listUsers.length === 0 && (
                                <div style={{ color: "red" }}>NÃO FOI ENCONTRADO NENHUM REGISTRO</div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal isOpen={createModalOpen} toggle={() => setCreateModalOpen(!createModalOpen)} backdrop="static" size="lg">
                <ModalHeader toggle={() => setCreateModalOpen(!createModalOpen)}>Adicionar novo usuário</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleCreateRoutineType}>
                        <FormGroup>
                            <Label className="form-control-label" htmlFor="input-customer">Selecione o qual será o tipo do usuário:</Label>
                            <Input
                                className="form-control-alternative"
                                type="select"
                                name="userTypeId"
                                id="userTypeId"
                                value={newUser.userTypeId}
                                onChange={handleCreateInputChange}
                                required
                            >
                                <option value="">Selecione um tipo</option>
                                {listUserType.map((userType) => (
                                    <option key={userType.id} value={userType.id}>
                                        {userType.type}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label className="form-control-label" htmlFor="input-description">
                                Nome
                            </Label>
                            <Input
                                className="form-control-alternative"
                                type="text"
                                name="name"
                                id="name"
                                value={newUser.name}
                                onChange={handleCreateInputChange}
                                required
                                placeholder="Nome do usuário"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="form-control-label" htmlFor="input-description">
                                Email
                            </Label>
                            <Input
                                className="form-control-alternative"
                                type="email"
                                name="email"
                                id="email"
                                value={newUser.email}
                                onChange={handleCreateInputChange}
                                required
                                placeholder="Email do usuário"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="form-control-label" htmlFor="input-description">
                                Senha
                            </Label>
                            <Input
                                className="form-control-alternative"
                                type="password"
                                name="password"
                                id="password"
                                value={newUser.password}
                                onChange={handleCreateInputChange}
                                required
                                placeholder="Senha do usuário"
                            />
                        </FormGroup>

                        <div className="d-flex justify-content-end mt-3">
                            <Button style={{ color: "#FFF", backgroundColor: "#585858" }} type="submit" size="m">Criar Usuário</Button>
                            <Button style={{ color: "#FFF", backgroundColor: "#585858" }} onClick={() => setCreateModalOpen(!createModalOpen)} size="m" className="ml-2">Cancelar</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal isOpen={editModalOpen} toggle={() => setEditModalOpen(!editModalOpen)} backdrop="static" className="custom-modal-xl">
                <ModalHeader toggle={() => setEditModalOpen(!editModalOpen)}>Editar informações do usuário</ModalHeader>
                <ModalBody>
                    {editingUser && (
                        <>
                            <Form onSubmit={handleEditSubmit}>
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-routineType">Tipo do Usuário</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="select"
                                        name="userTypeId"
                                        id="userTypeId"
                                        value={editingUser.userTypeId}
                                        onChange={e => setEditingUser({ ...editingUser, userTypeId: Number(e.target.value) })}
                                        required
                                    >
                                        <option value="">Selecione um tipo</option>
                                        {listUserType.map((userType) => (
                                            <option key={userType.id} value={userType.id}>
                                                {userType.type}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-description">Nome</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        name="type"
                                        id="type"
                                        value={editingUser.name}
                                        onChange={e => setEditingUser({ ...editingUser, name: e.target.value })}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-description">Email</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        name="type"
                                        id="type"
                                        value={editingUser.email}
                                        onChange={e => setEditingUser({ ...editingUser, email: e.target.value })}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-description">Senha</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        name="type"
                                        id="type"
                                        value={editingUser.password}
                                        onChange={e => setEditingUser({ ...editingUser, password: e.target.value })}
                                    />
                                </FormGroup>
                                <div className="d-flex justify-content-end">
                                    <Button style={{ color: "#FFF", backgroundColor: "#585858" }} type="submit" size="m">Salvar alterações</Button>
                                    <Button style={{ color: "#FFF", backgroundColor: "#585858" }} onClick={() => setEditModalOpen(!editModalOpen)} size="m">Cancelar</Button>
                                </div>
                            </Form>
                        </>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default AddUser;