import React, { useContext, useEffect, useState } from 'react';
import { Container, Table, Button, Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';
import PaginationMaterial from "../../components/PaginationMaterial/PaginationMaterial";
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Header from '../../components/Headers/Header';
import AdminNavbar from '../../components/Navbars/AdminNavbar/AdminNavbar';
import './Routine.css';
import { AuthContext } from '../../context/AuthContext';
import { RoutineTypeContext } from '../../context/RoutineTypeContext';
import { RoutineTypeType, CreateRoutineTypeType, UpdateRoutineTypeType } from '../../context/hooks/useRoutinetype';

const RoutineType = () => {
    const { objUserTk } = useContext(AuthContext);
    const {
        getAllRoutineTypePaged,
        listRoutineType,
        routineType,
        pageCount,
        setRoutineType,
        createRoutineType,
        updateRoutineType,
        deleteRoutineType,
    } = useContext(RoutineTypeContext);

    const [selectedRoutineTypeId, setSelectedRoutineTypeId] = useState(0);
    const [newRoutineType, setNewRoutineType] = useState<CreateRoutineTypeType>({
        type: '',
        officeId: objUserTk.OfficeId,
    });

    const [editingRoutineType, setEditingRoutineType] = useState<UpdateRoutineTypeType | null>(null);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [filters, setFilters] = useState({
        type: '',
    });

    useEffect(() => {
        setLoading(true);
        getAllRoutineTypePaged(1, objUserTk.OfficeId)
            .then(data => {
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao buscar tipos de rotinas:", error);
                setLoading(false);
            });
    }, []);

    const onChangePaginationCommon = async (e: any, value: any) => {
        await getAllRoutineTypePaged(value, objUserTk.OfficeId);
    };

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSearch = () => {
        setLoading(true);
        getAllRoutineTypePaged(
            1,
            objUserTk.OfficeId,
        )
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao aplicar filtros:", error);
                setLoading(false);
            });
    };

    const handleClearFilters = () => {
        getAllRoutineTypePaged(
            1,
            objUserTk.OfficeId,
        )
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao liimpar filtros:", error);
                setLoading(false);
            });
    };

    const handleCreateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewRoutineType(prevRoutineType => ({
            ...prevRoutineType,
            [name]: value === '' ? null : value
        }));
    };

    const handleCreateRoutineType = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const payload = {
            ...newRoutineType,
            type: newRoutineType.type,
            officeId: Number(newRoutineType.officeId)
        };

        createRoutineType(payload)
            .then(createdRoutineType => {
                if (createdRoutineType) {
                    setCreateModalOpen(false);
                    setNewRoutineType({
                        type: '',
                        officeId: objUserTk.OfficeId,
                    });
                    getAllRoutineTypePaged(1, objUserTk.OfficeId);
                } else {
                    console.error("Erro ao criar tipo de rotina.");
                }
            })
            .catch(error => {
                console.error("Erro ao criar tipo de rotina:", error);
            });
    };

    const handleEditRoutineType = (routineType: RoutineTypeType) => {
        const updateData: UpdateRoutineTypeType = {
            id: routineType.id,
            type: routineType.type,
        };
        setEditingRoutineType(updateData);
        setEditModalOpen(true);
    };

    const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (editingRoutineType) {
            updateRoutineType(editingRoutineType)
                .then(updatedRoutineType => {
                    if (updatedRoutineType) {
                        setEditModalOpen(false);
                        getAllRoutineTypePaged(1, objUserTk.OfficeId);
                        setEditingRoutineType(null);
                    } else {
                        console.error("Erro ao atualizar tipo de rotina.");
                    }
                })
                .catch(error => {
                    console.error("Erro ao atualizar o tipo da rotina:", error);
                });
        }
    };

    const handleDeleteRoutineType = (routineTypeId: number) => {
        if (window.confirm("Tem certeza que deseja excluir esta rotina?")) {
            deleteRoutineType(routineTypeId)
                .then(deletedRoutineType => {
                    if (deletedRoutineType) {
                        getAllRoutineTypePaged(1, objUserTk.OfficeId);
                    } else {
                        console.error("Erro ao excluir tipo de rotina.");
                    }
                    getAllRoutineTypePaged(1, objUserTk.OfficeId);
                })
                .catch(error => {
                    console.error("Erro ao excluir tipo de rotina:", error);
                });
        }
    };

    return (
        <>
            <LoadingSpinner />
            <Header />
            <AdminNavbar
                brandText={"Tipos de Rotinas"}
                search={false}
                titleSearch={""}
            />

            <Container className="mt-5">
                <Row>
                    <Col xs="12">
                        {/* <Form className="d-flex search-form">
                            <FormGroup className="mr-3">
                                <Label for="description">Rotina</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="text"
                                    name="description"
                                    id="description"
                                    value={filters.description}
                                    placeholder='Descrição da rotina'
                                    onChange={handleFilterChange}
                                />
                            </FormGroup>
                            <Button className="search-btn" onClick={handleSearch}>Pesquisar</Button>
                            <Button className="clear-btn" onClick={handleClearFilters}>Limpar</Button>
                        </Form> */}
                        <Button
                            style={{ color: "#FFF", backgroundColor: "#585858", float: "left" }}
                            className="btn-filter"
                            onClick={() => setCreateModalOpen(true)}
                        >
                            Adicionar tipo de rotina
                        </Button>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <Table hover responsive className="table-striped custom-table">
                            <thead>
                                <tr>
                                    <th>Tipo</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listRoutineType.map((routineType: RoutineTypeType, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td>{routineType.type}</td>
                                            <td>
                                                <Button onClick={() => {handleEditRoutineType(routineType); setSelectedRoutineTypeId(routineType.id);}}><i className="fa-solid fa-pen-to-square"></i></Button>
                                                <Button onClick={() => handleDeleteRoutineType(routineType.id)} color="danger"><i className="fa-solid fa-trash"></i></Button>
                                            </td>

                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                            {pageCount && (
                                <div className="d-flex justify-content-center mt-4">
                                    <PaginationMaterial
                                        onChange={(e: any, value: any) => onChangePaginationCommon(e, value)}
                                        count={pageCount}
                                    />
                                </div>
                            )}
                            {listRoutineType.length === 0 && (
                                <div style={{ color: "red" }}>NÃO FOI ENCONTRADO NENHUM REGISTRO</div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal isOpen={createModalOpen} toggle={() => setCreateModalOpen(!createModalOpen)} backdrop="static" size="lg">
                <ModalHeader toggle={() => setCreateModalOpen(!createModalOpen)}>Adicionar novo tipo de rotina</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleCreateRoutineType}>
                        <FormGroup>
                            <Label className="form-control-label" htmlFor="input-description">
                                Tipo de rotina:
                            </Label>
                            <Input
                                className="form-control-alternative"
                                type="text"
                                name="type"
                                id="type"
                                value={newRoutineType.type}
                                onChange={handleCreateInputChange}
                                required
                                placeholder="Ex: Administrativas, Jurídicas..."
                            />
                        </FormGroup>

                        <div className="d-flex justify-content-end mt-3">
                            <Button style={{ color: "#FFF", backgroundColor: "#585858" }} type="submit" size="m">Criar Tipo de Rotina</Button>
                            <Button style={{ color: "#FFF", backgroundColor: "#585858" }} onClick={() => setCreateModalOpen(!createModalOpen)} size="m" className="ml-2">Cancelar</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal isOpen={editModalOpen} toggle={() => setEditModalOpen(!editModalOpen)} backdrop="static" className="custom-modal-xl">
                <ModalHeader toggle={() => setEditModalOpen(!editModalOpen)}>Editar informações do tipo da rotina</ModalHeader>
                <ModalBody>
                    {editingRoutineType && (
                        <>
                            <Form onSubmit={handleEditSubmit}>
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-description">Tipo da rotina</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        name="type"
                                        id="type"
                                        value={editingRoutineType.type}
                                        onChange={e => setEditingRoutineType({ ...editingRoutineType, type: e.target.value })}
                                        required
                                    />
                                </FormGroup>
                                <div className="d-flex justify-content-end">
                                    <Button style={{ color: "#FFF", backgroundColor: "#585858" }} type="submit" size="m">Salvar alterações</Button>
                                    <Button style={{ color: "#FFF", backgroundColor: "#585858" }} onClick={() => setEditModalOpen(!editModalOpen)} size="m">Cancelar</Button>
                                </div>
                            </Form>
                        </>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default RoutineType;