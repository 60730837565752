import { Button, Container, Row, Col } from "reactstrap";
import "./UserHeader.css";
import { useContext } from "react";
import { CandidateContext } from "../../../context/CandidateContext";
import { AuthContext } from "../../../context/AuthContext";
import { CompanyContext } from "../../../context/CompanyContext";
const UserHeader = () => {
  const { candidate } = useContext(CandidateContext);
  const { company } = useContext(CompanyContext);
  const { selectTypeUser } = useContext(AuthContext);

  return (
    <>
      <div
        className="header pb-8 lg-8 d-flex align-items-center"
        style={{
          background: "linear-gradient(90deg, rgba(2,0,36,1) 10%, rgba(30,29,102,1) 62%, rgba(56,56,163,1) 100%, rgba(0,212,255,1) 100%)",
          // backgroundImage:
          //   "url(" +
          //   require(selectTypeUser === 1
          //     ? "../../../assets/img/theme/img-aluno-header.jpg"
          //     : "../../../assets/img/theme/img-recrutador-header.jpg") +
          //   ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span
          className={
            selectTypeUser === 1
              ? "mask bg-translucent-pharmacy opacity-8"
              : "mask bg-translucent-pharmacy-recruiter opacity-8"
          }
        />
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10">
              {/* <h1 className="display-2 text-white">
                OLÁ {selectTypeUser === 1 ? candidate.name.toLocaleUpperCase() : company.name.toLocaleUpperCase()}
              </h1>
              <p className="text-white mt-0 mb-5">
                {selectTypeUser === 1
                  ? "Neste portal, você terá acesso a diversas oportunidades de emprego como Propagandista em todo o Brasil. Além disso, poderá cadastrar seu currículo para integrar a rede dos gestores que estão contratando."
                  : "Neste portal, você terá acesso a profissionais qualificados que atendem aos requisitos desejados para a posição de Propagandista Vendedor."}
              </p> */}
              {/* <Button
                className={
                  selectTypeUser === 1
                    ? "button-custom"
                    : "button-custom-recruiter"
                }
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                {selectTypeUser === 1
                  ? "Encaminhar Currículo"
                  : "Anunciar Nova Vaga"}
              </Button> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
